var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"b-dialog",attrs:{"persistent":"","transition":"dialog-bottom-transition","retain-focus":false,"max-width":800,"value":_vm.dialog},on:{"input":function($event){return _vm.closeModal(false)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Visualizar histórico")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","id":"btn-close","name":"btn-close"},on:{"click":function($event){return _vm.closeModal(false)}}},[_c('v-icon',[_vm._v("fa-solid fa-xmark")])],1)],1),_c('v-card-text',{staticClass:"b-dialog--content"},[_c('v-data-table',{staticClass:"mt-5",attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.lifeCapitalMinimumCapitalLimit`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.lifeCapitalMinimumCapitalLimit))+" ")]}},{key:`item.lifeCapitalMaximumCapitalLimit`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.lifeCapitalMaximumCapitalLimit))+" ")]}},{key:`item.effectiveDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.effectiveDate))+" ")]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
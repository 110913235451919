<template>
  <v-dialog
    persistent
    class="b-dialog"
    transition="dialog-bottom-transition"
    :retain-focus="false"
    :max-width="800"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Visualizar histórico</v-toolbar-title>
        <v-spacer />
        <v-btn icon id="btn-close" name="btn-close" @click="closeModal(false)">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="b-dialog--content">
        <v-data-table
          dense
          disable-sort
          class="mt-5"
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.lifeCapitalMinimumCapitalLimit`]="{ item }">
            {{ formatMoney(item.lifeCapitalMinimumCapitalLimit) }}
          </template>

          <template v-slot:[`item.lifeCapitalMaximumCapitalLimit`]="{ item }">
            {{ formatMoney(item.lifeCapitalMaximumCapitalLimit) }}
          </template>

          <template v-slot:[`item.effectiveDate`]="{ item }">
            {{ formatDate(item.effectiveDate) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ContractService } from '@/services/api/contracts';
import { formatMoney, formatDate } from '@/helpers/formatting';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    customerId: {
      type: String
    },
    contractId: {
      type: String
    },
    capital: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    options: {},
    headers: []
  }),

  created() {
    this.filteredHeaders();
  },

  methods: {
    formatMoney,
    formatDate,

    closeModal() {
      this.$emit('closeModal');
    },

    filteredHeaders() {
      let headers = [
        { text: 'Descrição', value: 'lifeCapitalDescription' },
        { text: 'Limite mínimo', value: 'lifeCapitalMinimumCapitalLimit' },
        { text: 'Limite máximo', value: 'lifeCapitalMaximumCapitalLimit' },
        {
          text: 'Múltiplo salarial',
          value: 'lifeCapitalQuantityMultipleSalary'
        },
        { text: 'Data de vigência', value: 'effectiveDate' }
      ];

      if (this.capital.lifeCapitalType !== 'Múltiplo salarial') {
        headers = headers.filter(
          header => header.value !== 'lifeCapitalQuantityMultipleSalary'
        );
      }

      this.headers = headers;
    },

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const service = new ContractService();
        const { status, data } =
          await service.getLifeCapitalReadjustmentHistories({
            page,
            take: itemsPerPage,
            contractId: this.contractId,
            customerId: this.customerId
          });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
